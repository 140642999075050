<script setup>

</script>

<template>
  <div id="discount">优惠券订单</div>
</template>

<style lang="scss" scoped>
#discount {
  height: 100%;
}
</style>
